import * as React from 'react';
import { ITaxingAuthority } from '../../../model/Common/TaxingAuthority';
import { Body } from '../../common/StepLayout/Body';
import { StepLayout } from '../../common/StepLayout/StepLayout';
import { Button } from 'react-bootstrap';
import { AssignToDelegatee } from './AssignToDelegatee';
import { DeclineSigning } from './DeclineSigning';
import { IPublicData } from '../signflow/Helper/SignerSignFlowHelper';
import { ISignerData } from '../Sign/Esign/Action/EsignActions';
import { ClientTypes } from '../../../model/Common/Enums';
import { hideFinishButton } from '../../common/Helper/HelperFunctions';

interface LandingPageProps {
    taxingAuthorities: ITaxingAuthority[];
    onNext(): void;
    onCancelDelegatePopUp(): void;
    showDelegatePopUp: boolean;
    onShowDelegatePopup(): void;
    onHideDelegateHelpClick(): void;
    showDelegateHelpPopUp: boolean;
    onShowDelegateHelpPopUp(): void;
    onShowDeclineSigningPopUp(): void;
    showDeclineSigningPopUp: boolean;
    onHideDeclineSigningPopUp(): void;
    onDelegateSaveClick: (signerName: string, emailAddress: string, changeReason: string) => void;
    onDelegateReassignClick: (signerName: string, emailAddress: string, changeReason: string) => void;
    delegationCancelled: () => void;
    emailAddress: string;
    signerName: string;
    changeReason: string;
    DeclineSigning: (declineReason: string) => void;
    primaryDetailsStore: IPublicData;
    signatureInfoStore: ISignerData;
}

export const LandingPage: React.FunctionComponent<LandingPageProps> = (props) => {
    React.useEffect(() => {
        hideFinishButton(); //Updation of Remove Finish button is issue in Awesome-pdf-viewer this function is just for hiding the Finsh button for the bug-80417
    }, [props])

    return (<React.Fragment>
        <StepLayout>

            <Body title={""}
                lg={10} md={12} sm={12} xs={12} height={99}
                automationTestId={"02E35FF2-0334-4230-AF75-FA28D0E1DF8A"}
                bodyClassName="text-align-center" >
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div style={{ flex: 4 }}>
                        <br />
                        <div data-test-auto="C59B1A54-96C0-4D69-8E70-57EC10D4EDC1">
                            <span>Welcome {props.primaryDetailsStore.primaryDetails.clientName?.toUpperCase()},</span><br />
                            <span> {props.primaryDetailsStore.primaryDetails.companyData.companyName?.toUpperCase()}</span><br />
                            <span>has sent you a {props.signatureInfoStore?.DocumentDetails[0]?.documentType.name} that needs to be signed</span>
                        </div>
                        <br />
                    </div>


                    <div style={{ flex: 2 }} className={"landingPageFooter"}>
                        <p>Please select an option below</p>

                        <Button
                            onClick={props.onNext}
                            data-test-auto="197B103D-43B8-42AD-8372-600D47EF6709"
                            className="rounded marB10 landingPageFooterBtnWidth"
                            variant={"primary"}>
                            {
                                props.signatureInfoStore.AssignToDelegateeSigner == true || (props.signatureInfoStore.SignatureStatus.delegateeId != null &&
                                    props.signatureInfoStore.SignatureStatus.clientType != ClientTypes.Delegatee) ? "View or Download Documents" : "Sign Documents"
                            }
                        </Button>
                        <br />

                        {props.signatureInfoStore.AssignToDelegateeSigner == true || (props.signatureInfoStore.SignatureStatus.delegateeId != null &&
                            props.signatureInfoStore.SignatureStatus.clientType != ClientTypes.Delegatee) ?
                            <React.Fragment>
                                <Button
                                    onClick={() => { props.onShowDelegatePopup() }}
                                    data-test-auto="32B6FEAC-98AB-4FF5-9D2A-3921E40DCF8A"
                                    className="btn btn-white btn-default rounded bootbox-delegate marB10 landingPageFooterBtnWidth"
                                    variant={"light"}> Update Delegation
                         </Button>
                                <br />
                            </React.Fragment>
                            :
                            (props.signatureInfoStore.SignatureStatus.clientType != ClientTypes.Delegatee) ?
                                <React.Fragment>
                                    <Button
                                        disabled={ !props.primaryDetailsStore.primaryDetails.signerAllowedToDelegate }
                                        onClick={() => { props.onShowDelegatePopup() }}
                                        data-test-auto="C0140F7E-E69D-4597-9E91-736D990FEF0B"
                                        title={!props.primaryDetailsStore.primaryDetails.signerAllowedToDelegate ? "You don't have an access to delegate to some one. Please contact system administrator for more information." : ""}
                                        className="btn btn-white btn-default rounded bootbox-delegate marB10 landingPageFooterBtnWidth"
                                        variant={"light"}> Delegate to Someone Else
                                 </Button>
                                    <br />
                                </React.Fragment> : ""
                        }

                        {props.signatureInfoStore.AssignToDelegateeSigner == true || props.signatureInfoStore.SignatureStatus.delegateeId != null ?

                            <Button
                                onClick={() => { props.delegationCancelled() }}
                                data-test-auto="8F03A787-097E-40F6-9AF1-054EDC5C16D2"
                                className="btn btn-white rounded bootbox-cancel-signing landingPageFooterBtnWidth"
                                variant={"light"}> Cancel Delegation
                        </Button>
                            :
                            <Button
                                onClick={() => { props.onShowDeclineSigningPopUp() }}
                                data-test-auto="6F3FC407-FA48-48E5-A660-F857B280D696"
                                className="btn btn-white rounded bootbox-cancel-signing landingPageFooterBtnWidth"
                                variant={"light"}> Decline to Sign
                        </Button>
                        }

                    </div>
                    <br />
                </div>
            </Body>

        </StepLayout>

        <AssignToDelegatee
            key="assignToDelegatee"
            onHide={props.onCancelDelegatePopUp}
            showState={props.showDelegatePopUp}
            onHideDelegateHelpClick={props.onHideDelegateHelpClick}
            showDelegateHelpPopUp={props.showDelegateHelpPopUp}
            onShowDelegateHelpPopUp={props.onShowDelegateHelpPopUp}
            onDelegateSaveClick={props.onDelegateSaveClick}
            signerName={props.signerName}
            emailAddress={props.emailAddress}
            changeReason={props.changeReason}
            AssignToDelegateeSigner={props.signatureInfoStore.AssignToDelegateeSigner}
            DelegationAssigned={props.signatureInfoStore.SignatureStatus.delegateeId}
            onDelegateReassignClick={props.onDelegateReassignClick}
            onDelegationCancelled={props.delegationCancelled}
        />

        <DeclineSigning
            key="declineSigning"
            onHide={props.onHideDeclineSigningPopUp}
            showState={props.showDeclineSigningPopUp}
            DeclineSigning={props.DeclineSigning}
        />

    </React.Fragment>);
}