import "reflect-metadata";
import { Container } from "inversify";
import { IDataService } from "../../services/dataAccess/abstraction/IDataService";
import { Factory } from "./Factory";
import { TYPES } from "./types";
import { UtilityFactory } from "../../utilities/factory/UtilityFactory";
import { IDialogBox } from "../../utilities/ui/DialogBox";
import { IWindowUtilities } from "../../utilities/WindowUtilities";
// import { IHtmlUtilities } from "../../utilities/HtmlUtilities";
import { ITextUtilities } from "../../utilities/TextUtilities";
import { ILocalStore } from "../../utilities/LocalStore";
import { IRegexUtilities, RegexUtilities } from "../../utilities/RegexUtilities";
import { IUtilities, Utilities } from "../../utilities/Utilities";
import { FileUploadUtilities, IFileUploadUtilities } from "../../utilities/FileUpload/FileUploadUtilities";
import { FileUtilities, IFileUtilities } from "../../utilities/File/FileUtilities";
import { IJsonUtilities, JsonUtilities } from "../../utilities/JSON/JsonUtilities";
import { DateUtilities, IDateUtilities } from "../../utilities/date/DateUtilities";
import { ILoader, Loader } from "../../utilities/ui/Loader";
import { ISnackbar, Snackbar } from "../../utilities/ui/Snackbar";


const container = new Container();

container.bind<IDataService>(TYPES.IDataService).toConstantValue(Factory.createDataService());
container.bind<ILocalStore>(TYPES.ILocalStore).toConstantValue(new UtilityFactory().createLocalStore());
container.bind<ITextUtilities>(TYPES.ITextUtilities).toConstantValue(new UtilityFactory().createTextUtilityService());
container.bind<IWindowUtilities>(TYPES.IWindowUtilities).toConstantValue(new UtilityFactory().createWindowUtilityService());
// container.bind<IHtmlUtilities>(TYPES.IHtmlUtilities).toConstantValue(new UtilityFactory().createHtmlUtilities());
container.bind<IDialogBox>(TYPES.IDialogBox).toConstantValue(new UtilityFactory().createDialogBox());
container.bind<IRegexUtilities>(TYPES.IRegexUtilities).to(RegexUtilities);
container.bind<IUtilities>(TYPES.IUtilities).to(Utilities).inSingletonScope();
container.bind<IFileUploadUtilities>(TYPES.IFileUploadUtilities).to(FileUploadUtilities);
container.bind<IFileUtilities>(TYPES.IFileUtilities).to(FileUtilities);
container.bind<IJsonUtilities>(TYPES.IJsonUtilities).to(JsonUtilities);
container.bind<IDateUtilities>(TYPES.IDateUtilities).to(DateUtilities);
container.bind<ILoader>(TYPES.ILoader).to(Loader).inSingletonScope();
container.bind<ISnackbar>(TYPES.ISnackbar).to(Snackbar);


export { container };
