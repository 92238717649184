import React, { useState, useEffect } from 'react';
import { IPublicData } from '../signflow/Helper/SignerSignFlowHelper';
import { IClientSignatureStatus } from '../../../model/ViewModel/ClientSignatureStatus';
import SignerHelper from '../signflow/Helper/SignerHelper';
import { RouteComponentProps } from 'react-router';
import { handleRedirect } from '../Common/RedirectHelper';
import { ISnackbar } from '../../../core/utilities/ui/Snackbar';
import { container } from '../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../core/startup/inversify/types';

const snackbar = container.get<ISnackbar>(TYPES.ISnackbar);

export type MobileOtpProps = {
    primaryDetailsStore: IPublicData;
    requestSignatureInfo: (clientId: string, callback: (status: IClientSignatureStatus, clientId: any, ignoreStatus: boolean) => void) => void;
    requestMobileOtp: (clientId: string, successCallback?: () => void) => void;
    validateMobileOtp: (clientId: string, otp: string, successCallback: any) => void;
}
    & RouteComponentProps<{}>

export const MobileOtp: React.FunctionComponent<MobileOtpProps> = (props) => {

    const [otp, setOtp] = useState('');
    const [disabledContinueButton, setDisabledContinueButton] = useState(true);

    useEffect(() => {
        validateMobileOtp();
    }, [otp])

    const handleMobileOtpChange = (evt: any) => {
        let value = evt.target.value;
        let numbers = value.replace(/[^0-9]/g, "");
        evt.target.value = numbers;
        value = evt.target.value;
        setOtp(value);
    }

    const handleSendMobileOtpBtnClick = () => {
        let param: any = props.match.params;
        props.requestMobileOtp(param.Id);
    }

    const handleValidateMobileOtpBtnClick = () => {
        let param: any = props.match.params;
        if (otp && parseInt(otp)) {
            if (otp.toString().length != 8) {
                snackbar.show("Enter 8 digit one-time access code");
            }
            else {
                 props.validateMobileOtp(param.Id, otp, checkClientSignatureStatus);
            }
        }
        else {
            snackbar.show("Enter a valid one-time access code");
        }
    }

    const validateMobileOtp = () => {
        otp
            && Number(otp)
            && otp.toString().length == 8 ?
            setDisabledContinueButton(false) :
            setDisabledContinueButton(true)
    }

    const checkClientSignatureStatus = (clientId: any) => {
        props.requestSignatureInfo(clientId, (status: IClientSignatureStatus, clientId: any, ignoreStatus: boolean) =>
            handleRedirect(status, clientId, ignoreStatus, props.history));
    }

    return (
        <div className="container container-height">
            <div className="form-horizontal mobileAuthDiv form-horizontal-height">
                <div className="">
                    <h4>Authentication</h4>
                </div>
                <div className="">
                    <label className="form-label form-label-bold">Mobile Access Code Required</label>
                </div>
                <div className="">
                    <label className="form-label fontMed">Please select “Request Access Code” and we will send you a one-time expiring access code to your mobile device Number:</label>
                </div>
                <div className="">
                    <label className="form-label form-label-bold">{props.primaryDetailsStore.primaryDetails.authenticationInfo.maskedMobileNumber}</label>
                </div >
                <div className="">
                    <label className="form-label fontMed fontItalics"> (If the mobile number is incorrect please contact your Tax Professional)</label>
                </div>
                <div className="form-group">
                    <button type="button" className="btn btn-secondary" onClick={handleSendMobileOtpBtnClick}
                        id="linkResendMobileOtp">Request Access code</button>
                </div>

                <div className="">
                    <label className="form-label fontMed">Enter access code here:</label>
                </div>
                <div className="wid170">
                    <input id="MobileOtp" className="form-control form-control-green form-rounded"
                        autoComplete={"off"} name="txtOTP"
                        placeholder="Enter Access Code" type="text" pattern="[0-9]*" onChange={handleMobileOtpChange} />
                </div>
                <div className="form-group">
                    <small className="form-label fontItalics">(This code will expire in 20 minutes)</small>
                </div>
            </div>
            <div>
                <div className="form-group mobileContinueBtn">
                    <a className="rd-nav-link">
                        <button type="button" disabled={disabledContinueButton}
                            className="btn btn-primary btn-primary-extended"
                            onClick={handleValidateMobileOtpBtnClick} id="btnContinue">
                            Continue
                        </button>
                    </a>
                </div>
            </div>
        </div >
    );
}
