import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { container } from '../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../core/startup/inversify/types';
import { ISnackbar } from '../../../core/utilities/ui/Snackbar';
import { IClientSignatureStatus } from '../../../model/ViewModel/ClientSignatureStatus';
import { handleRedirect } from '../Common/RedirectHelper';
import { AuthenticationType, IPublicData } from '../signflow/Helper/SignerSignFlowHelper';

const snackbar = container.get<ISnackbar>(TYPES.ISnackbar);

type SSNComponentProps = {
    primaryDetailsStore: IPublicData;
    validateSSN: (clientId: string, ssn: string, successCallback: any) => void;
    requestSignatureInfo: (clientId: string, callback: (status: IClientSignatureStatus, clientId: any, ignoreStatus: boolean) => void) => void;
}
    & RouteComponentProps<{}>


export const SSNComponent: React.FunctionComponent<SSNComponentProps> = (props) => {

    const [SSN, setSSN] = useState("");
    const [disabledContinueButton, setContinueButton] = useState(true);

    React.useEffect(() => {
        validateSSN();
    }, [SSN]);

    const handleValidateSSNBtnClick = () => {
        let param: any = props.match.params;
        if (SSN) {
            if (SSN.toString().length != 4) {
                snackbar.show("Enter 4 digit SSN");
            }
            else {
                props.validateSSN(param.Id, SSN, checkClientSignatureStatus);
            }
        }
        else {
            snackbar.show("Enter a valid SSN");
        }
    }

    const validateSSN = () => {
        SSN
            && SSN.toString().length == 4 ?
            setContinueButton(false) :
            setContinueButton(true)
    }

    const checkClientSignatureStatus = (clientId: any) => {
        props.requestSignatureInfo(clientId, (status: IClientSignatureStatus, clientId: any, ignoreStatus: boolean) =>
            handleRedirect(status, clientId, ignoreStatus, props.history));
    }

    const handleSSNChange = (evt: any) => {
        let value = evt.target.value;
        let numbers = value.replace(/[^0-9]/g, "");
        evt.target.value = numbers;
        setSSN(evt.target.value);
    }

    const ssnType = props.primaryDetailsStore.primaryDetails.authenticationInfo.authenticationType == AuthenticationType.First4SSN ? "First" : "Last";

    return (
        <div className="container container-height">
            <div className="form-horizontal ssnAuthDiv form-horizontal-height">
                <div className="">
                    <h4>Authentication</h4>
                </div>
                <div className="">
                    <label className="form-label authentication-client-name">{props.primaryDetailsStore.primaryDetails.clientName}</label>
                </div>
                <div className="form-group">
                    <label className="form-label fontMed">Please enter the {ssnType?.toLowerCase()} four digits of your Social Security Number or EIN:</label>
                </div>
                <div className="form-group wid140">
                    <input id="SSN" className="form-control form-control-green form-rounded" autoComplete={"off"} name="txtSSN"
                        placeholder="Enter SSN/EIN" type="text" pattern="[0-9]*" onChange={handleSSNChange} />
                </div>
                <div className="form-group">
                    <small className="form-label fontItalics">(We use the {ssnType?.toLowerCase()} four digits to verify your identity)</small>
                </div>
            </div>
            <div>
                <div className="form-group ssnContinueBtn">
                    <a className="rd-nav-link">
                        <button type="button" className="btn btn-primary btn-primary-extended"
                            disabled={disabledContinueButton}
                            onClick={handleValidateSSNBtnClick} id="btnSSNContinue">
                            Continue
                        </button>
                    </a>
                </div>
            </div>
        </div >
    )
}