import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { container } from '../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../core/startup/inversify/types';
import { ISnackbar } from '../../../core/utilities/ui/Snackbar';
import { IClientSignatureStatus } from '../../../model/ViewModel/ClientSignatureStatus';
import { handleRedirect } from '../Common/RedirectHelper';
import { IPublicData } from '../signflow/Helper/SignerSignFlowHelper';

const snackbar = container.get<ISnackbar>(TYPES.ISnackbar);

type QNAComponentProps = {
    primaryDetailsStore: IPublicData;
    validateQNA: (clientId: string, answer: string, successCallback: any) => void;
    requestSignatureInfo: (clientId: string, callback: (status: IClientSignatureStatus, clientId: any, ignoreStatus: boolean) => void) => void;
    getQnAQuestion: (clientId: string, successCallback: any) => void;
}
    & RouteComponentProps<{}>


export const QnAComponent: React.FunctionComponent<QNAComponentProps> = (props) => {

    const [answer, setAnswer] = useState("");
    const [disabledContinueButton, setContinueButton] = useState(true);
    const [question, setQuestion] = useState("");

    React.useEffect(() => {
        validateAnswer();
        //let param: any = props.match.params;
        //props.getQnAQuestion(param.Id, (question: any) => {
        //    setQuestion(question);
        //});
    }, [answer]);

    const handleValidateAnswerBtnClick = () => {
        let param: any = props.match.params;
        if (answer) {
            if (answer.length >= 50) {
                snackbar.show("Maximum 50 characters allowed.");
            }
            else {
                props.validateQNA(param.Id, answer, checkClientSignatureStatus);
            }
        }
        else {
            snackbar.show("Enter an Answer.");
        }
    }

    const validateAnswer = () => {
        answer
            && answer.length <= 50 ?
            setContinueButton(false) :
            setContinueButton(true)
    }

    const checkClientSignatureStatus = (clientId: any) => {
        props.requestSignatureInfo(clientId, (status: IClientSignatureStatus, clientId: any, ignoreStatus: boolean) =>
            handleRedirect(status, clientId, ignoreStatus, props.history));
    }

    const handleAnswerChange = (evt: any) => {
        let value = evt.target.value;
        setAnswer(value);
    }

    return (
        <div className="container container-height">
            <div className="form-horizontal qnaAuthDiv form-horizontal-height">
                <div>
                    <h4>Authentication</h4>
                </div>
                <div className="">
                    <label className="form-label authentication-client-name">{props.primaryDetailsStore.primaryDetails.clientName}</label>
                </div>
                <div className="form-group">
                    <span className="form-label question">{props.primaryDetailsStore.primaryDetails.authenticationInfo.question}</span>
                </div>
                <div className="form-group wid140">
                    <input id="Answer" className="form-control form-control-green form-rounded"
                        autoComplete={"off"} name="txtAnswer"
                        placeholder="Enter Answer"
                        type="text" onChange={handleAnswerChange} />
                </div>
            </div>
            <div>
                <div className="form-group qnaContinueBtn">
                    <a className="rd-nav-link">
                        <button type="button" className="btn btn-primary btn-primary-extended"
                            disabled={disabledContinueButton}
                            onClick={handleValidateAnswerBtnClick} id="btnQNAContinue">
                            Continue
                        </button>
                    </a>
                </div>
            </div>
        </div>
    )
}