import * as React from 'react';
import { container } from '../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../core/startup/inversify/types';
import { ILocalStore } from '../../../core/utilities/LocalStore';

const localStore = container.get<ILocalStore>(TYPES.ILocalStore);

export const InvalidLink = () => {
    return (<div>
        <header id="header" className="app-header">
            <div id="div_CommonCompanyName" className="col-xs-5 col-sm-6 col-md-6 col-lg-5 title"></div>
            <div className="col-xs-7 col-sm-6 col-md-5 col-lg-5 pop-log">
                <div id="div_CommonHeaderContact" className="flRt">
                    <span className="clearfix"></span>
                </div>
            </div>
        </header>

        <div id="content-wrapper" className="col-xs-12">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 login-page">
                <div className="greeting text-center">
                    This link is no longer valid. Please contact the firm for additional information.
                </div>
            </div>
        </div>
    </div >)
}