import { ITaxingAuthority } from '../model/Common/TaxingAuthority';
import { ISignProcessInfo } from '../components/Pages/signflow/Helper/SignerSignFlowHelper';
import { IPublicData } from '../../src/components/Pages/signflow/Helper/SignerSignFlowHelper';
import { IPublicUserTokenData, primaryDetailsReducer, primarytokenReducer } from '../components/Pages/signflow/Action/SignerSignFlowActions';
import { ISignerData, signatureInfoReducer } from '../components/Pages/Sign/Esign/Action/EsignActions';
import { ITokenData, tokenReducer } from '../components/Pages/Login/Action/LoginActions';
import { downloadsReducer, IDownloadData } from '../components/Pages/SignCompleted/Action/DownloadableDocumentActions';

export interface ApplicationState {
    taxingAuthorities: ITaxingAuthority[];
    signProcessInfo: ISignProcessInfo;
    primaryDetails: IPublicData;
    signatureInfo: ISignerData;
    tokenInfo: ITokenData;
    documents: IDownloadData;
    primaryTokenInfo: IPublicUserTokenData;
};

export const reducers = {
    primaryDetails: primaryDetailsReducer,
    signatureInfo: signatureInfoReducer,
    tokenInfo: tokenReducer,
    documents: downloadsReducer,
    primaryTokenInfo: primarytokenReducer
};

export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction | AppThunkAction<TAction>) => void, getState: () => ApplicationState): void;
}