import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';

export interface DelegateHelpProps {
    showState: boolean;
    onHide: () => void;
}

export const DelegateHelp: React.FunctionComponent<DelegateHelpProps> = (props) => {

    const onHide = () => {
        props.onHide();
    }

    return (
        <div>
            <Modal show={props.showState} className="delegateHelp" onHide={onHide}
                data-test-auto="4A79EEE5-1474-4D71-AFAB-E484F99BF989" centered>
                <Modal.Body>
                    <div className="row text-align-center">
                        <div className="col-lg-12" style={{ display: 'inline' }}>

                            <h5 style={{ fontWeight: 'bold' }}>Delegate Signature To Someone Else</h5>
                            <p>The person you delegate the signing process to will receive an email notification with an access link. </p>
                            <p>Your sender will also receive a notification informing them that you have delegated this document for signature.</p>
                        </div>
                    </div>
                </Modal.Body>
                {
                    <Modal.Footer>
                        <div className="text-align-center">
                            <Button
                                id="btnHideMyaccountPopUp"
                                onClick={onHide}
                                data-test-auto="630ACD79-4E40-4E1E-9B35-999BFF26305B"
                                className="btn btn-primary rounded bootbox-accept"
                                variant={"primary"}> OK
                            </Button>
                        </div>
                    </Modal.Footer>}
            </Modal>
        </div >
    );
}