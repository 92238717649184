import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery'
import 'bootstrap';
import 'node-snackbar';
import App from "./App";
import '../node_modules/awesome-pdf-viewer/dist/index.css'
import '../node_modules/awesome-pdf-viewer/dist/styles/css/bootstrap.css';
import '../node_modules/react-checkbox-tree/lib/react-checkbox-tree.css';
import '../node_modules/node-snackbar/dist/snackbar.min.css';
import '../node_modules/awesome-multi-steps/dist/index.css';
import './assets/css/site.css'

ReactDOM.render(<App />, document.getElementById("app"));

if (module.hot) {
    module.hot.accept();
  }