import React, { useState} from 'react';
import { Button, Modal } from 'react-bootstrap';
import { CustomTextArea } from '../../common/TextBox/CustomTextArea';
import { ISnackbar } from '../../../core/utilities/ui/Snackbar';
import { container } from '../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../core/startup/inversify/types';

const snackbar = container.get<ISnackbar>(TYPES.ISnackbar);


export interface DeclineSigningProps {
    showState: boolean;
    onHide: () => void;
    DeclineSigning: (declineReason: string) => void;
}

export const DeclineSigning: React.FunctionComponent<DeclineSigningProps> = (props) => {

    const [declineReason, setDeclineReason] = useState('');


    const onHide = () => {
        props.onHide();
    }

    const handleDeclineReasonChange = (event: any) => {
        setDeclineReason(event.target.value);
    }

    const onDeclineSigning = () => {
        props.DeclineSigning(declineReason ?? "");
    }

    return (
        <div>
            <Modal show={props.showState} className="myaccount" onHide={onHide}
                data-test-auto="06F88193-3C98-4ABD-A441-8CFC0FBE75C9" centered>
                <Modal.Body>
                    <div className="row text-align-center">
                        <div className="col-lg-12" style={{ display: 'inline' }}>
                            <h5 style={{ fontWeight: 'bold' }}>Decline Signing Documents</h5>
                        </div>
                        <br /><br /><br />
                        <p>Are you sure you want to decline signing the documents sent by your tax preparer?</p>
                    </div>
                    <h6 className="header-bold"> Please enter the reason for declining below:</h6>
                    <div>
                        <textarea id="declineReason" className="form-control form-control-green form-rounded textarea-size"
                            data-test-auto="937A7120-57C9-408A-91BF-EDEEBE7F2647" placeholder="Please enter a reason"
                            onChange={handleDeclineReasonChange} />
                    </div>
                </Modal.Body>
                {
                <Modal.Footer>
                    <Button
                        id="btnHideDeclineSigningPopUp"
                        onClick={onHide}
                        data-test-auto="3263DC16-1363-4CFB-A38A-DA2583EEE78B"
                        className="btn btn-white btn-default rounded bootbox-cancel float-left"
                        variant={"light"}> Cancel
                    </Button>
                    <Button
                        onClick={onDeclineSigning}
                        data-test-auto="6C5820EA-5AEC-4FB6-BEAA-633C4776FE84"
                        className="btn btn-white rounded bootbox-cancel-signing float-right"
                        variant={"light"}> Decline to Sign

                    </Button>
                    </Modal.Footer>}
            </Modal>
        </div>
    );
}
