import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PhoneNumberComponent from '../../common/PhoneNumberComponent/PhoneNumberComponent';
import { EditableDropdown } from '../../common/Select/EditableDropdown';
import { CoutryCodes } from '../../../common/constants/CoutryCodes';


export interface ChangeNumberProps {
    showState: boolean;
    onSaveNumberClick: (number: string, countrycode: string) => void;
    onHideChangeNumberPopUp: () => void;
    mobileNumber: string;
    countryCode: string;
}

interface ChangeNumberState {
    editedMobileNumber: string;
    editedCountryCode: string;
}

export class ChangeNumber extends React.Component<ChangeNumberProps, ChangeNumberState>
{
    constructor(props: any) {
        super(props);
        this.state = {
            editedMobileNumber: "",
            editedCountryCode: ""
        }
    }

    private onMobileNumberChange = (value: string) => {
        this.setState({ editedMobileNumber: value });
    }

    private onChangeCountryCode = (value: string) => {
        this.setState({
            editedCountryCode: value
        });
    }

    onHide = () => {
        this.setState({ editedMobileNumber: this.props.mobileNumber }, () => this.props.onHideChangeNumberPopUp());
        this.setState({ editedCountryCode: this.props.countryCode }, () => this.props.onHideChangeNumberPopUp());
    }

    componentDidUpdate(prevProps: ChangeNumberProps) {
        if (prevProps.mobileNumber != this.props.mobileNumber) {
            this.setState({
                editedMobileNumber: this.props.mobileNumber,
                editedCountryCode: this.props.countryCode
            });
        }
    }

    public render() {
        return (
            <div>
                <div style={{ position: 'relative' }}>
                    <Modal show={this.props.showState} onHide={this.onHide} className="myaccount"
                        data-test-auto="3F647974-90BC-47E8-9019-D6BB02CC2601">
                        <Modal.Header closeButton >
                            <Modal.Title>
                                <span>
                                    Edit Mobile Number
                                </span>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row marB10">
                                <div className="col-lg-12" style={{ display: 'inline' }}>

                                    <label className="labelMobileNumber" >Enter Mobile Number</label>

                                    <div className="myaccount-text phonenumber" >
                                        <div className="phoneNumberSelect">
                                            <EditableDropdown
                                                id="ddlCountryCode"
                                                options={CoutryCodes()}
                                                onChange={this.onChangeCountryCode}
                                                selectedValue={this.state.editedCountryCode}
                                                data-test-auto="271C2A00-2C22-4E8A-9EEF-966E58AAE121"
                                            />
                                        </div>

                                        <PhoneNumberComponent
                                            phoneNumber={this.state.editedMobileNumber}
                                            handleChangePhoneNumber={this.onMobileNumberChange}
                                            data-test-auto="76F66251-527A-4C72-B07E-1E402EABB663"
                                            className="my-account-phonenumber"
                                        >
                                        </PhoneNumberComponent>

                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                id="btnHideChangeNumberPopUp"
                                onClick={this.onHide}
                                data-test-auto="E68460D2-1A81-418E-BE28-6E7C1F2E395B"
                                variant={"light"}
                                className="btn btn-white btn-default rounded bootbox-cancel">
                                <i className="fa fa-times"></i> Cancel
                            </Button>
                            <Button
                                onClick={() => { this.props.onSaveNumberClick(this.state.editedMobileNumber, this.state.editedCountryCode) }}
                                data-test-auto="511110AF-725E-45AD-8CFF-37790FC8B7C1"
                                variant={"primary"}
                                className="btn btn-primary rounded bootbox-accept">
                                <i className="fa fa-save"></i> Save
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        );
    }
}
export default ChangeNumber;