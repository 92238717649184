import { connect } from 'react-redux';
import { SigningSuccess } from '../SignCompleted/SigningSuccess'
import { ApplicationState } from '../../../reducers';
import { IClientSignatureStatus } from '../../../model/ViewModel/ClientSignatureStatus';
import { refreshToken } from '../Login/Action/LoginActions';
import { requestSignatureInfo } from '../Sign/Esign/Action/EsignActions';

const mapStateToProps = (state: ApplicationState) => ({
    primaryDetailsStore: state.primaryDetails,
    signatureInfoStore: state.signatureInfo
});

const mapDispatchToProps = (dispatch: any) => ({
    requestSignatureInfo: (clientId: string, successCallback: (status: IClientSignatureStatus, DataService: any, ignoreStatus: boolean) => void) =>
        dispatch(requestSignatureInfo(clientId, successCallback)),
    refreshToken: (clientId: string, successCallback?: () => void) => dispatch(refreshToken(clientId, successCallback)),
});

export const SigningSuccessContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SigningSuccess);