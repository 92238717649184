import { connect } from 'react-redux';
import { SignCompletedFlow } from '../SignCompleted/SignCompletedFlow'
import { ApplicationState } from '../../../reducers';
import { downloadAllDocument, requestDocuments } from '../SignCompleted/Action/DownloadableDocumentActions';
import { DownloadStep } from '../../../model/Common/Enums';

const mapStateToProps = (state: ApplicationState) => ({
    signatureInfoStore: state.signatureInfo,
    documents: state.documents
});

const mapDispatchToProps = (dispatch: any) => ({
    downloadAllDocument: (clientId: string, fileName: string, isSigningCompleted: boolean, downloadStep: DownloadStep) =>
        dispatch(downloadAllDocument(clientId, fileName, isSigningCompleted, downloadStep)),
    requestDocuments: (clientId: string,) => dispatch(requestDocuments(clientId))
});

export const TaxpayerSignCompletedFlowContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SignCompletedFlow);
