import * as React from 'react';
import { PdfView } from './PdfView';
import { IDocument } from '../../../../../model/Esign/Document';
import { DocumentSignatureDataModel } from '../../../../../model/Esign/IDocumentSignatureDataModel';
import { ViewerMode } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import { Main } from './Main';
import { ISnackbar } from '../../../../../core/utilities/ui/Snackbar';
import { container } from '../../../../../core/startup/inversify/inversify.config';
import { TYPES } from '../../../../../core/startup/inversify/types';
import { ISignerData } from '../../Esign/Action/EsignActions'
import { DownloadStep } from '../../../../../model/Common/Enums';
import { ISignerControlDataModal } from '../../../../../model/Esign/ISignerControlDataModal';
import { RouteComponentProps } from 'react-router';
import { IEventTelemetry } from '@microsoft/applicationinsights-web';
import { logger } from '../../../../../routes';
import { ISignFinishedResponse } from 'src/model/ViewModel/ISignFinishedResponse';

export const NO_INDEX = -1;

interface EsignState {
    documentControlsAndData: DocumentSignatureDataModel[],
    showTimeout: boolean,
    showSubmitLoader: boolean,
}

export type ESignProps = {
    requestSignatureControls: () => void;
    clientId: string;
    signatureControlsData: IDocument[];
    onSigningComplete: (signData: IDocument[]) => any;
    disableNextButton: (value: boolean) => void;
    completeSigningStep: () => void;
    sign: (clientId: string, signatureData: ISignerControlDataModal, callback?: (status: ISignFinishedResponse) => void) => void;
    onHeaderAndFooterVisibility: (visibility: boolean) => void;
    signatureInfoStore: ISignerData;
    downloadAllDocument: (clientId: string, fileName: string, isSigningCompleted: boolean, downloadStep: DownloadStep) => any;
}
    & RouteComponentProps<{}>

const snackbar = container.get<ISnackbar>(TYPES.ISnackbar);

export class Esign extends React.Component<ESignProps, EsignState> {

    private _pdfView: any;

    constructor(props: any, states: EsignState) {
        super(props, states);
        this.state = {
            documentControlsAndData: [],
            showTimeout: false,
            showSubmitLoader: false
        }
        this.props.disableNextButton(true);
    }

    componentDidMount() {
        if (this.props.signatureControlsData == undefined || this.props.signatureControlsData.length == 0) {
            //this.props.requestSignatureControls();
        }
        logger.trackPageView("Document Esign Page");
    }

    static getDerivedStateFromProps(nextProps: ESignProps, nextState: EsignState) {
        if (nextProps.signatureControlsData &&
            nextProps.signatureControlsData.length !== nextState.documentControlsAndData.length) {
            return { documentControlsAndData: nextProps.signatureControlsData }
        }
        else {
            return { documentControlsAndData: nextState.documentControlsAndData };
        }
    }

    handleDocumentSignFinish = (documentControlData: DocumentSignatureDataModel) => {
        let documentDataList: DocumentSignatureDataModel[] = [...this.state.documentControlsAndData];

        const index = documentDataList.findIndex(x => x.id === documentControlData.id);
        if (index == NO_INDEX) {
            documentDataList.push(documentControlData);
        }
        else {
            documentDataList[index] = documentControlData;
        }
        this.setState({ documentControlsAndData: documentDataList });
    }

    handleSigningCompleted = () => {
        this.props.disableNextButton(false);
    }

    onNext(index: number) {
        alert("Onnext esign");
    }

    handleViewModeChange = (viewerMode: ViewerMode) => {

        if (viewerMode == ViewerMode.Read) {
            this.props.onHeaderAndFooterVisibility(true);
        }
        else {
            this.props.onHeaderAndFooterVisibility(false);
        }
    }

    private downloadInitialDocuments = () => {
        let param: any = this.props.match.params;
        const fileName = this.props.signatureInfoStore.DocumentDetails[0].name.split('.');
        this.props.downloadAllDocument(param.clientId, fileName[0] + ".zip",
            this.props.signatureInfoStore.SignatureStatus.isAllSigned, DownloadStep.Esign)
    }


    public render() {

        return (<Main>
            {<PdfView
                documents={this.props.signatureInfoStore.DocumentDetails}
                onSigningCompleted={this.handleSigningCompleted}
                ref={(ref: PdfView) => this._pdfView = ref}
                downloadInitialDocuments={this.downloadInitialDocuments}
                signatureInfoStore={this.props.signatureInfoStore}
                disableNextButton={this.props.disableNextButton}
            />}
        </Main>);
    }
}
