import * as React from 'react';
import { OverlayLoader } from '../../common/Loader/OverlayLoader';

interface HeaderProps {
    companyName: string;
    companyLogo: string;
}

export const Header: React.FunctionComponent<HeaderProps> = (props) => {
    return (<div className="row header-right-container">
        <div className={"header-nav"} style={{ display: "inline-block" }}>
            {props.companyLogo ? <img className="company-logo" src={props.companyLogo} /> : <h3 className={"title"}>{props.companyName}</h3>}
            <OverlayLoader />
        </div>
    </div>);
}