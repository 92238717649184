import { RadioButton, SignatureMode } from "awesome-pdf-viewer/dist/Controls/ControlBase";

export interface IControl {
    id: string;
    top: number;
    left: number;
    width: number;
    height: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean
}



export interface ISignatureControl extends IControl {

}

export interface ITextBoxControl extends IControl {
    readOnly: boolean;
}

export interface ICheckBoxControl extends IControl {

}

export interface IRadioControl extends IControl {
    items: RadioButton[];
}

export interface ILabelControl extends IControl {

}



export interface IInitialControl extends ISignatureControl {


}

export interface INameAndTitleControl extends ITextBoxControl {


}

export interface ITitleControl extends ITextBoxControl {


}

export interface INameControl extends ITextBoxControl {


}

export interface ICompanyControl extends ITextBoxControl {


}

export interface ICustomTextControl extends ITextBoxControl {


}

export interface IDateControl extends ILabelControl {


}



export interface ICustomData {

}



export interface IControlData {


}

export interface ISignatureData extends IControlData {

    name: string;
    signature: string;
    signatureMode?: SignatureMode;
}

export interface ITextData extends IControlData {
    text: string;
}

export interface ICheckBoxData extends IControlData {
    checked: boolean;
}

export interface IRadioGroupData extends IControlData {
    items: RadioButton[]
}


export class SignatureControl implements ISignatureControl {

    id: string;
    top: number;
    left: number;
    width: number;
    height: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean

    constructor(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.width = width;
        this.height = height;
        this.tooltip = tooltip;
        this.required = required;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
    }

    static create(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): ISignatureControl {

        return new SignatureControl(id,
            top,
            left,
            width,
            height,
            tooltip,
            required,
            customData,
            data,
            disabled);

    }
}

export class InitialControl implements ISignatureControl {

    id: string;
    top: number;
    left: number;
    width: number;
    height: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean

    constructor(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.width = width;
        this.height = height;
        this.tooltip = tooltip;
        this.required = required;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
    }

    static create(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): ISignatureControl {

        return new InitialControl(id,
            top,
            left,
            width,
            height,
            tooltip,
            required,
            customData,
            data,
            disabled);
    }
}

export class NameAndTitleControl implements ITextBoxControl {

    readOnly: boolean;
    id: string;
    top: number;
    left: number;
    width: number;
    height: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.width = width;
        this.height = height;
        this.tooltip = tooltip;
        this.required = required;
        this.readOnly = readOnly;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
    }

    static create(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): ITextBoxControl {

        return new NameAndTitleControl(id,
            top,
            left,
            width,
            height,
            tooltip,
            required,
            readOnly,
            customData,
            data,
            disabled);

    }

}

export class TitleControl implements ITextBoxControl {

    readOnly: boolean;
    id: string;
    top: number;
    left: number;
    width: number;
    height: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.width = width;
        this.height = height;
        this.tooltip = tooltip;
        this.required = required;
        this.readOnly = readOnly;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
    }

    static create(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): ITextBoxControl {

        return new TitleControl(id,
            top,
            left,
            width,
            height,
            tooltip,
            required,
            readOnly,
            customData,
            data,
            disabled);

    }

}

export class NameControl implements ITextBoxControl {

    readOnly: boolean;
    id: string;
    top: number;
    left: number;
    width: number;
    height: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.width = width;
        this.height = height;
        this.tooltip = tooltip;
        this.required = required;
        this.readOnly = readOnly;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
    }

    static create(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): ITextBoxControl {

        return new NameControl(id,
            top,
            left,
            width,
            height,
            tooltip,
            required,
            readOnly,
            customData,
            data,
            disabled);

    }

}

export class CompanyControl implements ITextBoxControl {

    readOnly: boolean;
    id: string;
    top: number;
    left: number;
    width: number;
    height: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.width = width;
        this.height = height;
        this.tooltip = tooltip;
        this.required = required;
        this.readOnly = readOnly;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
    }

    static create(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): ITextBoxControl {

        return new CompanyControl(id,
            top,
            left,
            width,
            height,
            tooltip,
            required,
            readOnly,
            customData,
            data,
            disabled);

    }

}

export class CustomTextControl implements ITextBoxControl {

    readOnly: boolean;
    id: string;
    top: number;
    left: number;
    width: number;
    height: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.width = width;
        this.height = height;
        this.tooltip = tooltip;
        this.required = required;
        this.readOnly = readOnly;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
    }

    static create(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): ITextBoxControl {

        return new CustomTextControl(id,
            top,
            left,
            width,
            height,
            tooltip,
            required,
            readOnly,
            customData,
            data,
            disabled);

    }
}

export class DateControl implements ILabelControl {

    id: string;
    top: number;
    left: number;
    width: number;
    height: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.width = width;
        this.height = height;
        this.tooltip = tooltip;
        this.required = required;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
    }

    static create(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): ILabelControl {

        return new DateControl(id,
            top,
            left,
            width,
            height,
            tooltip,
            required,
            customData,
            data,
            disabled);

    }

}

export class CheckBoxControl implements ICheckBoxControl {
    id: string;
    top: number;
    left: number;
    width: number;
    height: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.width = width;
        this.height = height;
        this.tooltip = tooltip;
        this.required = required;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
    }

    static create(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): ICheckBoxControl {

        return new CheckBoxControl(id,
            top,
            left,
            width,
            height,
            tooltip,
            required,
            customData,
            data,
            disabled);

    }
}
export class RadioControl implements IRadioControl {
    id: string;
    top: number;
    left: number;
    width: number;
    height: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    items: RadioButton[];
    data?: IControlData;
    disabled?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        customData: ICustomData[],
        items: RadioButton[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.width = width;
        this.height = height;
        this.tooltip = tooltip;
        this.required = required;
        this.customData = customData;
        this.items = items;
        this.data = data;
        this.disabled = disabled;
    }

    static create(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        customData: ICustomData[],
        items: RadioButton[],
        data?: IControlData,
        disabled?: boolean): IRadioControl {

        return new RadioControl(id,
            top,
            left,
            width,
            height,
            tooltip,
            required,
            customData,
            items,
            data,
            disabled);

    }
}

export class PrintNameControl implements ITextBoxControl {

    readOnly: boolean;
    id: string;
    top: number;
    left: number;
    width: number;
    height: number;
    tooltip: string;
    required: boolean;
    customData: ICustomData[];
    data?: IControlData;
    disabled?: boolean;

    constructor(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean) {

        this.id = id;
        this.top = top;
        this.left = left;
        this.width = width;
        this.height = height;
        this.tooltip = tooltip;
        this.required = required;
        this.readOnly = readOnly;
        this.customData = customData;
        this.data = data;
        this.disabled = disabled;
    }

    static create(id: string,
        top: number,
        left: number,
        width: number,
        height: number,
        tooltip: string,
        required: boolean,
        readOnly: boolean,
        customData: ICustomData[],
        data?: IControlData,
        disabled?: boolean): ITextBoxControl {

        return new PrintNameControl(id,
            top,
            left,
            width,
            height,
            tooltip,
            required,
            readOnly,
            customData,
            data,
            disabled);

    }

}


export class TextData implements ITextData {

    text: string;

    constructor(text: string) {
        this.text = text;
    }

    static create(text: string): ITextData {
        return new TextData(text);
    }


}

export class CheckBoxData implements ICheckBoxData {

    checked: boolean;

    constructor(checked: boolean) {
        this.checked = checked;
    }

    static create(checked: boolean): ICheckBoxData {
        return new CheckBoxData(checked);
    }
}

export class RadioGroupData implements IRadioGroupData {

    items: RadioButton[]

    constructor(items: RadioButton[]) {
        this.items = items;
    }

    static create(items: RadioButton[]): IRadioGroupData {
        return new RadioGroupData(items);
    }
}