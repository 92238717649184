import "babel-polyfill";
import 'bootstrap';
import { ConnectedRouter } from 'connected-react-router';
import "isomorphic-fetch";
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './configureStore';
import { history } from './core/services/dataAccess/History';
import * as RoutesModule from './routes';
import { ApplicationState } from "./reducers";
let routes = RoutesModule.routes;


const App = () => {
    const initialState = (window as any).initialReduxState as ApplicationState;
    const store = configureStore(history, initialState);
    let routes = RoutesModule.routes;
    window.Variables = {}
   
   return( <Provider store={store}>
                <ConnectedRouter children={routes} history={history}/>
            </Provider>);
}

export default App;