import React, { useEffect } from 'react';
import { CustomButton, CustomButtonPlacement, CustomButtonSize } from '../../common/CustomButton/CustomButton';
import { Body } from '../../common/StepLayout/Body';
import { StepLayout } from '../../common/StepLayout/StepLayout';
import { Common } from '../../../common/constants/Constants';
import { DownloadStep } from '../../../model/Common/Enums';
import { ISignerData } from '../Sign/Esign/Action/EsignActions';
import { IDownloadData } from './Action/DownloadableDocumentActions';
import { RouteComponentProps } from 'react-router';
import { SvgIconComplete } from '../../common/Icons/SvgIcons';

export type SignCompletedFlowProps = {
    signatureInfoStore: ISignerData;
    documents: IDownloadData;
    downloadAllDocument: (clientId: string, fileName: string, isSigningCompleted: boolean, downloadStep: DownloadStep) => void;
    requestDocuments: (clientId: string) => void;
}
    & RouteComponentProps<{}>

export const SignCompletedFlow: React.FunctionComponent<SignCompletedFlowProps> = (props) => {

    useEffect(() => {
        let param: any = props.match.params;
        props.requestDocuments(param.clientId);
    }, []);


    const downloadAllDocuments = () => {
        let param: any = props.match.params;
        let fileName: string = "Signed_Files_" + Number(new Date()) + ".zip";
        props.downloadAllDocument(param.clientId, fileName,
            props.signatureInfoStore.SignatureStatus.isAllSigned, DownloadStep.Download);
    }

    const expireDate = props.documents.documents[0].documentType === "" ? "" : new Date(props.documents?.documents[0].expiresOn)?.toLocaleDateString()

    return (<React.Fragment>
        <Body title={""} bodyClassName={"sign-completed-body"}
            lg={10} md={12} sm={12} xs={12} height={90}
            automationTestId={"4903FAD2-DEA0-423F-82E5-22D85B6BD89B"}  >
            <br />
            <br />
            <div>
                <h5 data-test-auto="58C9B25A-1816-44A7-BF26-EE614AFC1C24" style={{ fontWeight: 600 }}>
                    You have successfully signed the document(s)
                        </h5>
                <br />
                <br />
                <br />

                <SvgIconComplete className={"sign-completed-icon"}></SvgIconComplete>
                {/* <img src={SvgIconComplete}  /> */}

                <br />
                <br />
                <br />

                <p data-test-auto="58C9B25ABF26EE614AFC1C24" className="font-weight-normal-bold">
                    You'll receive a copy via email once everyone has signed.
                </p>

            </div>

            <br />

            <div>
                <CustomButton icon={Common.Images.Download} placement={CustomButtonPlacement.Center}
                    size={CustomButtonSize.XL} content={"Download All Documents"} onClick={downloadAllDocuments} />
            </div>

            <div className="marT10" style={{ marginBottom: '40px' }}>
                Expires on {expireDate}
            </div>
        </Body>
    </React.Fragment>);
}
