import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { IPublicData } from '../../signflow/Helper/SignerSignFlowHelper';
import { ISignerData } from '../../Sign/Esign/Action/EsignActions';
import { IClientSignatureStatus } from '../../../../model/ViewModel/ClientSignatureStatus';
import SignerHelper from '../../signflow/Helper/SignerHelper';
import { handleRedirect } from '../RedirectHelper';
import { logger } from '../../../../routes';

let moment = require('moment');

export type DeclinedProps =
    {
        primaryDetailsStore: IPublicData;
        signatureInfoStore: ISignerData;
        requestSignatureInfo: (clientId: string, callback: (status: IClientSignatureStatus, clientId: any, ignoreStatus: boolean) => void) => void;
    }
    & RouteComponentProps<{}>

export const Declined: React.FunctionComponent<DeclinedProps> = (props) => {

    useEffect(() => {
        let param: any = props.match.params;
        let clientId = param.clientId;
        if (props.signatureInfoStore.SignatureStatus.signingId == 0) {
            props.requestSignatureInfo(clientId, (status: IClientSignatureStatus, clientId: any, ignoreStatus: boolean) =>
                handleRedirect(status, clientId, true, props.history));
        } else if (props.signatureInfoStore?.SignatureStatus.declinedBy == null) {
            props.requestSignatureInfo(clientId, (status: IClientSignatureStatus, clientId: any, ignoreStatus: boolean) => null);
        }
        logger.trackPageView("Document declined Page");
    }, []);


    let gmtDateTime = moment.utc(props.signatureInfoStore?.SignatureStatus.declinedOn);
    let local = gmtDateTime.local().format('YYYY-MMM-DD h:mm A');
    return <React.Fragment>
        <div id="content-wrapper" className="col-xs-12 content-wrapper-scroll">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ marginTop: "35px" }}>
                <div className="greeting text-left" style={{ width: "100%" }}>
                    <h5 style={{ fontWeight: 600 }}>Document Unavailable!</h5><br />
                    <br />
                    <p className="marB0 font15" style={{ fontWeight: 600 }}>The document you are attempting to access has been declined by {props.signatureInfoStore?.SignatureStatus.declinedBy} at {local} for the following reason:  </p>
                    <p className="marT5 font15">{props.signatureInfoStore?.SignatureStatus.declinedRemarks}</p>
                    <br />
                    <br />
                    <p className="marB0 font15">For more information, please contact the sender.</p>
                    <p className="marB0 marT10 font15">{props.primaryDetailsStore?.primaryDetails.companyData.companyName}</p>
                    <p className="marB0 font15"><a className="link-email">{props.signatureInfoStore?.SignatureStatus.senderEmailAddress}</a></p>
                </div>
            </div>
        </div>
    </React.Fragment>
}