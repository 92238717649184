import { injectable } from "inversify";

export interface IFileUtilities {
    getExtension(fileName: string): string;
    isValidateSize(file: any, maxSize: number): boolean;
    isValidateExtension(file: any, expectedExtensions: string[]): boolean;
    download(url: string, fileName?: string): void;
    show(blob: Blob, fileName: string): void;
}

@injectable()
export class FileUtilities implements IFileUtilities {

    getExtension(fileName: string): string {
        return fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
    }

    isValidateSize(file: any, maxSize: number): boolean {
        return file.size <= maxSize;
    }

    isValidateExtension(file: any, expectedExtensions: string[]): boolean {
        const fileExtension: string = this.getExtension(file);
        return expectedExtensions.indexOf(fileExtension.toLowerCase()) !== -1;
    }

    download(url: string, fileName?: string): void {
        let link: HTMLAnchorElement = document.createElement('a');
        link.href = url;
        link.target = "_blank";
        if (fileName) {
            link.download = fileName;
        }
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
            document.body.removeChild(link);
        }, 100);
    }

    show(blob: Blob, fileName: string): void {
        var base64 = this._arrayBufferToBase64(blob);
        var datauri = 'data:application/zip' + ';base64,' + base64;

        var link = document.createElement('a');
        link.href = datauri;
        link.download = fileName;
        link.target = "_blank";
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
            window.URL.revokeObjectURL(datauri);
            document.body.removeChild(link);
        }, 100);
    }

    _arrayBufferToBase64(buffer: any) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
}