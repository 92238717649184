import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Layout } from './components/Pages/Layout/Layout';
import { SignerSignFlowContainer } from './components/Pages/signflow/SignerSignFlowContainer';
import { TaxpayerSignCompletedFlowContainer } from './components/Pages/SignCompleted/SignCompletedContainer';
import { InvalidLink } from '../src/components/Pages/Common/InvalidLink';
import { RouteSelectorContainer } from './components/Pages/Common/RouteSelectorContainer';
import { LogoutSuccess } from './components/Pages/Common/LogoutSuccess';
import { DeclinedContainer } from './components/Pages/Common/Declined/DeclinedContainer';
import { StampingNotCompleteContainer } from './components/Pages/Common/StampingNotCompleteContainer';
import { CancelledContainer } from './components/Pages/Common/Cancelled/CancelledContainer';
import { ExpiredContainer } from './components/Pages/Common/Expired/ExpiredContainer';
import { SigningSuccessContainer } from './components/Pages/SignCompleted/SigningSuccessContainer';
import { LoggerFactory } from './Logger/LoggerFactory';
export const logger = new LoggerFactory().getTelemetryLogger();
logger.trackTrace("Initialize");

export const routes = (
    <Switch>
        <RouteWrapper exact path="/signer/:clientId" component={SignerSignFlowContainer} layout={Layout} />
        <RouteWrapper exact path="/login/:Id" component={RouteSelectorContainer} layout={Layout} />
        <RouteWrapper exact path="/declined/:clientId" component={DeclinedContainer} layout={Layout} />
        <RouteWrapper exact path="/cancelled/:clientId" component={CancelledContainer} layout={Layout} />
        <RouteWrapper exact path="/expired/:clientId" component={ExpiredContainer} layout={Layout} />
        <RouteWrapper exact path="/signcompleted/:clientId" component={TaxpayerSignCompletedFlowContainer} layout={Layout} />
        <RouteWrapper exact path="/signingprogress/:clientId" component={StampingNotCompleteContainer} layout={Layout} />
        <RouteWrapper exact path="/signingsuccess/:clientId" component={SigningSuccessContainer} layout={Layout} />
        <RouteWrapper exact path="/logout/:clientId" component={LogoutSuccess} />
        <RouteWrapper exact path="/invalid" component={InvalidLink} />
        <RouteWrapper exact path="/" component={InvalidLink} />

    </Switch>);

function RouteWrapper({
    component: Component,
    layout: Layout,
    ...rest
}: any) {
    return (
        <Route {...rest} render={(props) =>
            Layout ?
                <Layout {...props}>
                    <Component {...props} />
                </Layout> :
                <Component {...props} />
        } />
    );
}