import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { SlideBar } from "./DefaultLayout";
import { ApplicationState } from '../../../reducers/index';
import { requestPrimaryDetails } from '../signflow/Action/SignerSignFlowActions';
import { dispatchUserToken, refreshToken } from '../Login/Action/LoginActions';


function mapStateToProps(state: ApplicationState) {
    return {
        primaryDetailsStore: state.primaryDetails,
        tokenInfo: state.tokenInfo,
        primaryTokenInfo: state.primaryTokenInfo,
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        requestPrimaryDetails: (Id: string,successCallback?: (data: any) => void) => dispatch(requestPrimaryDetails(Id,successCallback)),
        refreshToken: (clientId: string, successCallback?: () => void) => dispatch(refreshToken(clientId, successCallback)),
        dispatchUserToken: (clientId: string) => dispatch(dispatchUserToken(clientId)),
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(connector(SlideBar));