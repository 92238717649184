import { inMemoryToken, storeTokenInMemory } from '../../../../core/services/dataAccess/DataService.Axios';
import { axiosFetch } from "../../../../core/services/dataAccess/DataService.Axios";
import { actionTypes } from "../../../../common/constants/actionTypes";
import { IDataService } from "../../../../core/services/dataAccess/abstraction/IDataService";
import { container } from "../../../../core/startup/inversify/inversify.config";
import { TYPES } from "../../../../core/startup/inversify/types";
import { ILoader } from "../../../../core/utilities/ui/Loader";
import { ISnackbar } from "../../../../core/utilities/ui/Snackbar";
import { NotificationType } from '../../../../model/Common/Enums';
import { IResponseNotifier } from '../../../../model/ViewModel/IResponseNotifier';
import { ILocalStore } from '../../../../core/utilities/LocalStore';


const dataService = container.get<IDataService>(TYPES.IDataService);
const loader = container.get<ILoader>(TYPES.ILoader);
const snackbar = container.get<ISnackbar>(TYPES.ISnackbar);
const localStore = container.get<ILocalStore>(TYPES.ILocalStore);

export interface ITokenData {
    token: string;
}


export const requestOTP = (clientId: string, callback?: () => void) => (dispatch: any) => {
    loader.show();
    dataService.get('api/authenticate/accessCode/' + clientId).then(function (response: any) {

        const { data } = response;
        if (data) {
            snackbar.show("A one-time access code was sent to your email");
        } else {
            snackbar.show("Failed to send one-time access code . Please check with the contact person");
        }
        loader.hide();
        if (callback) {
            callback();
        }
    }).catch(function (error: any) {
        console.log(error);
        loader.hide();
    });
}

export const validateOTP = (clientId: string, otp: string, callback: any) => (dispatch: any) => {
    loader.show();
    dataService.post('api/authenticate/' + otp + '/' + clientId).then(function (response: any) {
        const result: IResponseNotifier = response.data;
        if (result.Type === NotificationType.Success) {
            dispatch({
                type: actionTypes.RECEIVE_USER_TOKEN, token: result.Data.Token
            });
            storeTokenInMemory(result.Data.ClientGuid, result.Data.Token);
            localStore.set("loggedIn", true);
            loader.hide();
            if (callback) {
                callback(result.Data.ClientGuid);
            }
        }
        else {
            loader.hide();
            snackbar.show(result.Message);
        }
    }).catch(function (error: any) {
        console.log(error);
        loader.hide();
    });
}

export const refreshToken = (clientId: string, callback?: () => void) => (dispatch: any) => {
    loader.show();
    if (clientId) {
        dataService.get('api/authenticate/refresh/' + clientId
        ).then(function (response: any) {
            const { data } = response;
            storeTokenInMemory(clientId, data.Data);
            dispatch({
                type: actionTypes.RECEIVE_USER_TOKEN, token: data.Data
            });
            localStore.set("loggedIn", true);
            loader.hide();
            if (callback) {
                callback();
            }
        }).catch(function (error: any) {
            console.log(error);
            loader.hide();
        });
    } else {
        dispatch({
            type: actionTypes.RECEIVE_USER_TOKEN, token: "undefined"
        });
        loader.hide();
    }
}

export const dispatchUserToken = (clientId: string) => (dispatch: any) => {
    //const clientId: string = SignerHelper.getClientId();
    if (localStore.get('loggedIn')) {
        const publicUserToken = inMemoryToken.get(clientId);
        dispatch({
            type: actionTypes.RECEIVE_PUBLIC_USER_ACCESSTOKEN, accessToken: { token: publicUserToken != undefined ? publicUserToken : "", isLoading: false }
        });
    }
}

export const validateSSN = (clientId: string, ssn: string, callback: any) => (dispatch: any) => {
    loader.show();
    dataService.post('api/authenticate/' + ssn + '/' + clientId).then(function (response: any) {
        const result: IResponseNotifier = response.data;
        if (result.Type === NotificationType.Success) {
            dispatch({
                type: actionTypes.RECEIVE_USER_TOKEN, token: result.Data.Token
            });
            storeTokenInMemory(result.Data.ClientGuid, result.Data.Token);
            localStore.set("loggedIn", true);
            loader.hide();
            if (callback) {
                callback(result.Data.ClientGuid);
            }
        }
        else {
            loader.hide();
            snackbar.show(result.Message);
        }
    }).catch(function (error: any) {
        console.log(error);
        loader.hide();
    });
}

export const validateQnA = (clientId: string, answer: string, callback: any) => (dispatch: any) => {
    loader.show();
    dataService.post('api/authenticate/' + answer + '/' + clientId).then(function (response: any) {
        const result: IResponseNotifier = response.data;
        if (result.Type === NotificationType.Success) {
            dispatch({
                type: actionTypes.RECEIVE_USER_TOKEN, token: result.Data.Token
            });
            storeTokenInMemory(result.Data.ClientGuid, result.Data.Token);
            localStore.set("loggedIn", true);
            loader.hide();
            if (callback) {
                callback(result.Data.ClientGuid);
            }
        }
        else {
            loader.hide();
            snackbar.show(result.Message);
        }
    }).catch(function (error: any) {
        console.log(error);
        loader.hide();
    });
}

export const getQnAQuestion = (clientId: string, callback: any) => (dispatch: any) => {
    callback("What is your last name?");
}

export const requestMobileOTP = (clientId: string, callback?: () => void) => (dispatch: any) => {
    loader.show();
    dataService.get('api/authenticate/accessCode/' + clientId).then(function (response: any) {

        const { data } = response;
        if (data) {
            snackbar.show("A one-time access code was sent to you via a text message");
        } else {
            snackbar.show("Failed to send one-time access code. Please check with the contact person");
        }
        loader.hide();
        if (callback) {
            callback();
        }
    }).catch(function (error: any) {
        console.log(error);
        loader.hide();
    });
}

export const validateMobileOTP = (clientId: string, otp: string, callback: any) => (dispatch: any) => {
    loader.show();
    dataService.post('api/authenticate/' + otp + '/' + clientId).then(function (response: any) {
        const result: IResponseNotifier = response.data;
        if (result.Type === NotificationType.Success) {
            dispatch({
                type: actionTypes.RECEIVE_USER_TOKEN, token: result.Data.Token
            });
            storeTokenInMemory(result.Data.ClientGuid, result.Data.Token);
            localStore.set("loggedIn", true);
            loader.hide();
            if (callback) {
                callback(result.Data.ClientGuid);
            }
        }
        else {
            loader.hide();
            snackbar.show(result.Message);
        }
    }).catch(function (error: any) {
        console.log(error);
        loader.hide();
    });
}

const unloadedState: ITokenData = {
    token: ""
} as ITokenData;


export const tokenReducer = (state: ITokenData = unloadedState, action: any) => {

    switch (action.type) {

        case actionTypes.RECEIVE_USER_TOKEN: {
            return {
                token: action.token
            } as ITokenData;
        }
    }
    return state || unloadedState;
};