import React, { useEffect } from 'react';
import { inMemoryToken } from '../../../core/services/dataAccess/DataService.Axios';
import SignerHelper from '../signflow/Helper/SignerHelper';
import { RouteComponentProps } from 'react-router';

type SessionCheckProps = {
    refreshToken: (clientId: string, callback?: () => void) => void;
    dispatchUserToken: (clientId: string) => void;
}
    & RouteComponentProps<{}>

export const SessionCheck: React.FunctionComponent<SessionCheckProps> = (props) => {

    useEffect(() => {
        let param: any = props.match.params;
        if (!inMemoryToken.has(param.clientId)) {
            props.refreshToken(param.clientId);
        } else {
            props.dispatchUserToken(param.clientId);
        }
    }, []);


    return <React.Fragment>
        <div>
            <div style={{ height: "100%" }}></div>
        </div >
    </React.Fragment>
}