import { SvgIconDownloadAll } from "../../components/common/Icons/SvgIcons"

export const ErrorMessages = {
    TaxingAuthorityError: "Error occured while loading Taxing Authority"
}

export const Common = {
    Wizard: {
        PreviousButtonLabel: "Back ",
        NextButtonLabel: "Continue ",
        SendLater: "Send Later ",
        ForwardDocuments: "Forward a copy of my tax documents",
        SubmitButtonLabel: "Done"
    },
    DialogBox: {
        ConfirmationTitle: "Sign Out Confirmation",
        WarningTitle: "Warning",
        Cancel: "Cancel",
        Confrim: "Confirm",
        Yes: "Yes",
        No: "No",
        ConfirmMessage: 'Are you sure you want to sign out?',

    },
    Images: {
        Download: SvgIconDownloadAll,
    }
}

export const MyAccountConstants = {
    StatusMessage: {
        EditTaxpayerDetails: 'Taxpayer details updated successfully',
        EditMobileNumber: 'Mobile number updated successfully',
        UpdateInformation: 'Account details updated successfully',
    },
    WarningMessage:
    {
        InvalidEmailAddress: 'Please enter valid email address'
    }
}


export const SignProcessConstants = {
    ProcessRecoveryConfirmation: "Sign Process Recovery Confirmation",
    ProcessRecoveryMessage: "Would like to resume where you left off or start over ?",
    ProcessRecoveryConfirmationDialogLeftButton: "Resume",
    ProcessRecoveryConfirmationDialogRightButton: "Start over",
    ProcessRecoveryFailedMessage: "some settings has been changed, starting from first",
    DocumentAlreadySigned: "Document(s) already signed.",
}

export const PathConstants = {
    Invalid: "/invalid",
    Cancelled: "/cancelled/",
    Declined: "/declined/",
    Expired: "/expired/",
    SigningProgress: "/signingprogress/",
    Esign: "/signer/",
    Logout: "/logout/",
    Download:"/signcompleted/"
}

export const SignCompletedConstants = {
    CheckMark: '../../../images/icon-complete.svg',
    DownloadAllText: 'Download all my tax documents',
}

export class EsignStrings {
    public static readonly SIGNATURE_VALIDATION_ERROR_MESSAGE = "Signature not found !!";
    public static readonly COMPANY_VALIDATION_ERROR_MESSAGE = "Company name not found !!";
    public static readonly DATE_VALIDATION_ERROR_MESSAGE = "Date not found !!";
    public static readonly INITIAL_VALIDATION_ERROR_MESSAGE = "Initial not found !!";
    public static readonly NAME_AND_TITLE_VALIDATION_ERROR_MESSAGE = "Name and Title not found !!";
    public static readonly NAME_VALIDATION_ERROR_MESSAGE = "Name not found !!";
    public static readonly TITLE_VALIDATION_ERROR_MESSAGE = "Title not found !!";
    public static readonly CUSTOM_TEXTBOX_VALIDATION_ERROR_MESSAGE = "Some custom field data not found !!";
    public static readonly CHECKBOX_VALIDATION_ERROR_MESSAGE = "Checkbox not found !!";
}