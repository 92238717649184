import { injectable } from "inversify";

export interface ILoader {
    show(text?: string): void;
    hide(): void;
}

@injectable()
export class Loader implements ILoader {
    index = 0;

    public show(text?: string): void {
        this.index++;
        const loaderElement: any = document.getElementById("overlay-loader");
        loaderElement && loaderElement.setAttribute("data-show", "true");
    }

    public hide(): void {
        this.index--;
        if (this.index == 0) {
            const loaderElement: any = document.getElementById("overlay-loader");
            loaderElement && loaderElement.removeAttribute("data-show");
        }
    }

}