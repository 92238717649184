import { PathConstants } from "../../../common/constants/Constants";
import { History } from 'history';
import { IClientSignatureStatus, SignatureDocumentStatus, SignatureStatus, StampingStatus } from '../../../model/ViewModel/ClientSignatureStatus';
import { ILocalStore } from "../../../core/utilities/LocalStore";
import { container } from "../../../core/startup/inversify/inversify.config";
import { TYPES } from "../../../core/startup/inversify/types";

const localStore = container.get<ILocalStore>(TYPES.ILocalStore);

export function handleRedirect(status: IClientSignatureStatus, clientId: any, ignoreStatus: boolean, history: History) {

    if (!ignoreStatus && status == undefined
        || (Object.keys(status).length === 0 && status.constructor === Object)
        || (status && (status.documentStatus === SignatureDocumentStatus.Deleted ||
            status.documentStatus === SignatureDocumentStatus.Recycled ||
            status.signatureStatus === SignatureStatus.SignatureStampingFailed))
    ) {
       localStore.remove('loggedIn');
        history.push(`${PathConstants.Invalid}`);
    }
    else if (status) {
        if (status.signatureStatus === SignatureStatus.Cancelled) {
            history.push(`${PathConstants.Cancelled}${clientId}`);
        }
        else if (status.signatureStatus === SignatureStatus.Declined) {
            history.push(`${PathConstants.Declined}${clientId}`);
        }
        else if (status.isExpired) {
            history.push(`${PathConstants.Expired}${clientId}`);
        }
        else if (status.isSigned || status.isAllSigned ||
            (status.hasDelegateeSigned != null && status.hasDelegateeSigned)) {
            history.push(`${PathConstants.Download}${clientId}`);
        }
        else {
            if (status.stampingStatus === StampingStatus.Processing ||
                status.stampingStatus === StampingStatus.Failed) {
                history.push(`${PathConstants.SigningProgress}${clientId}`);
            }
            else
                history.push(`${PathConstants.Esign}${clientId}`);
        }
    }
}