import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { AuthenticationType, IPublicData } from '../signflow/Helper/SignerSignFlowHelper';
import { PublicLogin } from '../Common/PublicLogin';
import { IClientSignatureStatus } from '../../../model/ViewModel/ClientSignatureStatus';
import { ITokenData } from '../Login/Action/LoginActions';
import { IPublicUserTokenData } from '../signflow/Action/SignerSignFlowActions';
import { Shimmer } from '../../common/Shimmer/Shimmer';
import { history } from '../../../core/services/dataAccess/History';
import { AuthenticationLogin } from '../Login/AuthenticationLogin';

export interface RouteSelectorProps extends RouteComponentProps {
    primaryDetailsStore: IPublicData;
    tokenInfo: ITokenData;
    primaryTokenInfo: IPublicUserTokenData;
    requestSignatureInfo: (clientId: string, callback: (status: IClientSignatureStatus, clientId: any, ignoreStatus: boolean) => void) => void;
    getTokenForAnonymous: (id: string, callback: (clientId: any) => void) => void;
    refreshToken: (clientId: string, callback: () => void) => void;
    requestOTP: (clientId: string, successCallback?: () => void) => void;
    validateOTP: (clientId: string, otp: string, successCallback: any) => void;
    validateSSN: (clientId: string, ssn: string, successCallback: any) => void;
    validateQnA: (clientId: string, answer: string, successCallback: any) => void;
    getQnAQuestion: (clientId: string, successCallback: any) => void;
    requestMobileOTP: (clientId: string, successCallback?: () => void) => void;
    validateMobileOTP: (clientId: string, otp: string, successCallback: any) => void;
    requestStates: (clientId: string, successCallback: any) => void;
}

export const RouteSelector: React.FunctionComponent<RouteSelectorProps> = (props) => {

    useEffect(() => {
        if (props.primaryTokenInfo.token != "") {
            history.goForward();
        }
    }, []);

    return (
        !props.primaryDetailsStore.isLoading &&
            props.primaryDetailsStore.primaryDetails.authenticationInfo.authenticationType != undefined ?
            props.primaryDetailsStore.primaryDetails && props.primaryDetailsStore.primaryDetails.authenticationInfo.authenticationType == AuthenticationType.None ?
                <PublicLogin
                    publicData={props.primaryDetailsStore}
                    requestSignatureInfo={props.requestSignatureInfo}
                    history={props.history}
                    location={props.location}
                    match={props.match}
                    getTokenForAnonymous={props.getTokenForAnonymous}
                    tokenInfo={props.tokenInfo}
                    primaryTokenInfo={props.primaryTokenInfo}
                />
                : <AuthenticationLogin
                    getQnAQuestion={props.getQnAQuestion}
                    history={props.history}
                    location={props.location}
                    match={props.match}
                    primaryDetailsStore={props.primaryDetailsStore}
                    requestMobileOTP={props.requestMobileOTP}
                    requestOTP={props.requestOTP}
                    requestSignatureInfo={props.requestSignatureInfo}
                    requestStates={props.requestStates}
                    validateMobileOTP={props.validateMobileOTP}
                    validateOTP={props.validateOTP}
                    validateQnA={props.validateQnA}
                    validateSSN={props.validateSSN}
                />
            : <React.Fragment> <Shimmer height={1000} /> </React.Fragment>
    )
}