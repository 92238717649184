import { PathConstants } from '../../../../common/constants/Constants';

const NO_INDEX = -1;

export default class SignerHelper {

    static redirectToSignCompletedPage(clientId: string) {
        window.location.href = `${PathConstants.SigningProgress}${clientId}`;
    }


    static getClientId(): string {
        let clientId = undefined;
        if ((window as any).Variables.Id) {
            clientId = (window as any).Variables.Id;
        } else {
            clientId = (window as any).Variables.clientId;
        }
        return clientId;
    }

    static setClientId(clientid: string): string {
        (window as any).Variables.clientId = clientid;
        return (window as any).Variables.clientId;
    }
}
