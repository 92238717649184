import { actionTypes } from "../../../../../common/constants/actionTypes";
import { axiosFetch } from "../../../../../core/services/dataAccess/DataService.Axios";
import { container } from "../../../../../core/startup/inversify/inversify.config";
import { TYPES } from "../../../../../core/startup/inversify/types";
import { ILoader } from "../../../../../core/utilities/ui/Loader";
import { IDocument } from "../../../../../model/Esign/Document";
import { ISignerControlDataModal } from "../../../../../model/Esign/ISignerControlDataModal";
import { IClientSignatureStatus, initialClientSignatureStatus } from "../../../../../model/ViewModel/ClientSignatureStatus";
import SignerHelper from "../../../signflow/Helper/SignerHelper";
import { DocumentAdapter, IDocumentAdapter } from "../Sign/Helper/ControlDataAdapter";
import { ISignFinishedResponse } from "src/model/ViewModel/ISignFinishedResponse";
var moment = require('moment-timezone');

const loader = container.get<ILoader>(TYPES.ILoader);

export interface ISignerData {
    SignatureStatus: IClientSignatureStatus;
    DocumentDetails: IDocument[];
    AssignToDelegateeSigner: boolean
}

const unloadedState: ISignerData = {
    SignatureStatus: initialClientSignatureStatus,
    DocumentDetails: [],
    AssignToDelegateeSigner: false
};

export function getUserTimeZone() {
    return encodeURIComponent(moment.tz.guess(true));
}

export const requestDocumentControls = (clientId: string, isControlsDisabled: boolean, skipTracking?: boolean, callback?: () => void) => (dispatch: any) => {

    //const clientid: string = SignerHelper.getClientId();
    loader.show();
    axiosFetch(clientId).get('api/Signing/GetDocumentSignatureDataAsync?skipTracking=' + skipTracking + '&userTimeZone=' + getUserTimeZone()).then(function (response: any) {

        const { data } = response;
        const documentAdapter: IDocumentAdapter = DocumentAdapter.create();

        dispatch({
            type: actionTypes.RECEIVE_DOCUMENT_CONTROLS, DocumentDetails: isControlsDisabled ?
                documentAdapter.convertToClientModelWithDisable(data) :
                documentAdapter.convertToClientModel(data)
        });
        loader.hide();
        if (callback) {
            callback();
        }
    }).catch(function (error: any) {
        console.log(error);
        loader.hide();
    });
}



export const assignToDelegatee = (clientid: string, email: string, name: string, reason: string, callback?: (status: boolean) => void) => (dispatch: any) => {

    //const clientid: string = SignerHelper.getClientId();
    const formData = new FormData();
    formData.append('email', email);
    formData.append('name', name);
    formData.append('reason', reason);

    loader.show();
    axiosFetch(clientid).get('api/Signing/checkIfValidDocument').then(function (response: any) {
        const { data } = response;
        if (data == true) {
            axiosFetch(clientid).post('api/Signing/AssignToDelegatee', formData).then(function (response: any) {
                const { data } = response;

                dispatch({
                    type: actionTypes.RECEIVE_ASSIGN_TO_DELEGATEE_STATUS, AssignToDelegateeSigner: response.data
                });
                loader.hide();
                if (callback) {
                    callback(response.data);
                }
                if (data) {
                    dispatch(requestDocumentControls(clientid, data, true));
                }

            }).catch(function (error: any) {
                console.log(error);
                loader.hide();
            });
        }
        else {
            window.location.href = './invalid';
        }
    }).catch(function (error: any) {
        console.log(error);
        loader.hide();
    });

};

export const updateDelegatedSigner = (clientId: string, email: string, name: string, reason: string, callback?: (status: boolean) => void) => (dispatch: any) => {
    //const clientid: string = SignerHelper.getClientId();
    const formData = new FormData();
    formData.append('email', email);
    formData.append('name', name);
    formData.append('reason', reason);

    loader.show();
    axiosFetch(clientId).get('api/Signing/checkIfValidDocument').then(function (response: any) {
        const { data } = response;
        if (data == true) {
            axiosFetch(clientId).post('api/Signing/UpdateDelegation', formData).then(function (response: any) {
                loader.hide();
                if (callback) {
                    callback(response.data);
                }
            }).catch(function (error: any) {
                console.log(error);
                loader.hide();
            });
        }
        else {
            window.location.href = './invalid';
        }
    }).catch(function (error: any) {
        console.log(error);
        loader.hide();
    });
}

export const delegationCancelled = (clientId: string, callback?: (status: boolean) => void) => (dispatch: any) => {
    //const clientid: string = SignerHelper.getClientId();
    loader.show();
    axiosFetch(clientId).get('api/Signing/checkIfValidDocument').then(function (response: any) {
        const { data } = response;
        if (data == true) {
            axiosFetch(clientId).post('api/Signing/DelegationCancel').then(function (response: any) {

                const { data } = response;
                if (data) {
                    dispatch({ type: actionTypes.DECLINE_DELEGATION, AssignToDelegateeSigner: false })
                    dispatch(requestDocumentControls(clientId, false, true));
                }
                loader.hide();
                if (callback) {
                    callback(response.data);
                }
            }).catch(function (error: any) {
                console.log(error);
                loader.hide();
            });
        }
        else {
            window.location.href = './invalid';
        }
    }).catch(function (error: any) {
        console.log(error);
        loader.hide();
    });
}


export const requestSignatureInfo = (clientId: string, callback: (status: IClientSignatureStatus, clientId: any, ignoreStatus: boolean) => void) => (dispatch: any) => {
    //const clientid: string = SignerHelper.getClientId();
    loader.show();
    axiosFetch(clientId).get('api/Signing/GetClientSignatureStatusAsync').then(function (response: any) {
        const result: IClientSignatureStatus = response.data;
        dispatch({
            type: actionTypes.RECEIVE_CLIENT_SIGNATURE_STATUS, SignatureStatus: response.data
        });
        loader.hide();
        if (callback) {
            callback(result, clientId, false);
        }
    }).catch(function (error: any) {
        console.log(error);
        loader.hide();
    });
}

export const declineSigning = (clientId: string, remarks: string, callback?: (status: boolean) => void) => (dispatch: any) => {

    //const clientid: string = SignerHelper.getClientId();
    loader.show();
    const formData = new FormData();
    formData.append('remarks', remarks);

    axiosFetch(clientId).get('api/Signing/checkIfValidDocument').then(function (response: any) {
        const { data } = response;
        if (data == true) {
            axiosFetch(clientId).post('api/Signing/DeclineSigning', formData).then(function (response: any) {
                loader.hide();
                if (callback) {
                    callback(true);
                }
            }).catch(function (error: any) {
                console.log(error);
                loader.hide();
            });
        }
        else {
            window.location.href = './invalid';
        }
    }).catch(function (error: any) {
        console.log(error);
        loader.hide();
    });
}


export const sign = (clientId: string, signatureData: ISignerControlDataModal, callback?: (status: ISignFinishedResponse) => void) => (dispatch: any) => {
    const options = {
        headers: {
            'Accept': 'application/json, text/plain, *',
            'Content-Type': 'application/json; charset=utf-8'
        }
    };

    //const clientid: string = SignerHelper.getClientId();
    loader.show();
    axiosFetch(clientId).get('api/Signing/checkIfValidDocument').then(function (response: any) {
        const { data } = response;
        if (data == true) {
            axiosFetch(clientId).postJson(signatureData, 'api/Signing/SignAsync?userTimeZone=' + getUserTimeZone()).then(function (response: any) {
                loader.hide();
                let signResponse :ISignFinishedResponse = { 
                    isSignFinish: true, 
                    signFinishMessage: response.data?.signFinishMessage ? response.data.signFinishMessage : ""
                };
                if (callback) {
                    callback(signResponse);
                    
                }
            }).catch(function (error: any) {
                console.log(error);
                loader.hide();
            });
        }
        else {
            window.location.href = './invalid';
        }
    }).catch(function (error: any) {
        console.log(error);
        loader.hide();
    });
}



export const signatureInfoReducer = (state: ISignerData = unloadedState, action: any) => {

    switch (action.type) {

        case actionTypes.RECEIVE_CLIENT_SIGNATURE_STATUS: {
            return {
                SignatureStatus: action.SignatureStatus,
                DocumentDetails: state.DocumentDetails,
                AssignToDelegateeSigner: action.SignatureStatus.delegateeId != null ? true : false
            } as ISignerData;
        }
        case actionTypes.RECEIVE_ASSIGN_TO_DELEGATEE_STATUS: {
            return {
                SignatureStatus: state.SignatureStatus,
                DocumentDetails: state.DocumentDetails,
                AssignToDelegateeSigner: action.AssignToDelegateeSigner,
            } as ISignerData;
        }
        case actionTypes.RECEIVE_DOCUMENT_CONTROLS: {
            return {
                SignatureStatus: state.SignatureStatus,
                DocumentDetails: action.DocumentDetails,
                AssignToDelegateeSigner: state.AssignToDelegateeSigner
            } as ISignerData;
        }
        case actionTypes.DECLINE_DELEGATION: {
            var signatureData: ISignerData = state;
            signatureData.SignatureStatus.delegateeId = undefined;
            return {
                SignatureStatus: state.SignatureStatus,
                DocumentDetails: state.DocumentDetails,
                AssignToDelegateeSigner: action.AssignToDelegateeSigner,

            } as ISignerData;
        }
    }

    return state;
};