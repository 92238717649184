import React from 'react';
import { RouteComponentProps } from 'react-router';
import { AuthenticationType, IPublicData } from '../signflow/Helper/SignerSignFlowHelper';
import { IClientSignatureStatus } from '../../../model/ViewModel/ClientSignatureStatus';
import { Otp } from '../Login/OtpComponent';
import { SSNComponent } from '../Login/SSNComponent';
import { QnAComponent } from '../Login/QnAComponent';
import { MobileOtp } from '../Login/MobileOtpComponent';

export interface AuthenticationLoginProps extends RouteComponentProps {
    primaryDetailsStore: IPublicData;
    requestSignatureInfo: (clientId: string, callback: (status: IClientSignatureStatus, clientId: any, ignoreStatus: boolean) => void) => void;
    requestOTP: (clientId: string, successCallback?: () => void) => void;
    validateOTP: (clientId: string, otp: string, successCallback: any) => void;
    validateSSN: (clientId: string, ssn: string, successCallback: any) => void;
    validateQnA: (clientId: string, answer: string, successCallback: any) => void;
    getQnAQuestion: (clientId: string, successCallback: any) => void;
    requestMobileOTP: (clientId: string, successCallback?: () => void) => void;
    validateMobileOTP: (clientId: string, otp: string, successCallback: any) => void;
    requestStates: (clientId: string, successCallback: any) => void;
}


export const AuthenticationLogin: React.FunctionComponent<AuthenticationLoginProps> = (props) => {

    React.useEffect(() => {
        history.pushState(null, document.title, location.href);
        window.onpopstate = (() => history.go(1));
    }, []);

    const getReactComponent = (param: AuthenticationType) => {
        switch (param) {
            case AuthenticationType.AccessCodeEmail:
                return <Otp
                    history={props.history}
                    location={props.location}
                    match={props.match}
                    primaryDetailsStore={props.primaryDetailsStore}
                    requestOTP={props.requestOTP}
                    requestSignatureInfo={props.requestSignatureInfo}
                    validateOTP={props.validateOTP}
                />;
            case AuthenticationType.First4SSN:
                return <SSNComponent
                    primaryDetailsStore={props.primaryDetailsStore}
                    requestSignatureInfo={props.requestSignatureInfo}
                    validateSSN={props.validateSSN}
                    history={props.history}
                    location={props.location}
                    match={props.match}
                />;
            case AuthenticationType.Last4SSN:
                return <SSNComponent
                    primaryDetailsStore={props.primaryDetailsStore}
                    requestSignatureInfo={props.requestSignatureInfo}
                    validateSSN={props.validateSSN}
                    history={props.history}
                    location={props.location}
                    match={props.match}
                />;
            case AuthenticationType.QuestionAnswer:
                return <QnAComponent
                    primaryDetailsStore={props.primaryDetailsStore}
                    requestSignatureInfo={props.requestSignatureInfo}
                    validateQNA={props.validateQnA}
                    getQnAQuestion={props.getQnAQuestion}
                    history={props.history}
                    location={props.location}
                    match={props.match}
                />;
            case AuthenticationType.AccessCodeSMS:
                return <MobileOtp
                    history={props.history}
                    location={props.location}
                    match={props.match}
                    primaryDetailsStore={props.primaryDetailsStore}
                    requestMobileOtp={props.requestMobileOTP}
                    requestSignatureInfo={props.requestSignatureInfo}
                    validateMobileOtp={props.validateMobileOTP}
                />;           
            default:
                return null;
        }
    }   


    return (    
        <React.Fragment> {getReactComponent(props.primaryDetailsStore.primaryDetails.authenticationInfo?.authenticationType)}</React.Fragment>
    )
}