import axios from 'axios';
import { PathConstants } from "../../../common/constants/Constants";
import { container } from '../../startup/inversify/inversify.config';
import { TYPES } from '../../startup/inversify/types';
import { ILocalStore } from '../../utilities/LocalStore';
import { IDataService } from "./abstraction/IDataService";
import { history } from './History';
import { SIGNER_BASE_URL } from "../../../utils/AppConstants";

export const inMemoryToken: Map<string, string> = new Map();
//const localStore = container.get<ILocalStore>(TYPES.ILocalStore);

export class AxiosDataService implements IDataService {

    private _baseUri: string = "";
    private _pageSize: number = 0;
    private _error: boolean = false;

    constructor(clientId?: string) {
        if (clientId && inMemoryToken.has(clientId)) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + inMemoryToken.get(clientId);
        }

        const _self = this;

        axios.interceptors.response.use(function (response) {
            // windowUtilities.hideLoader();
            return response;

        }, function (error: any) {

            if (_self._error == false) {

                _self._error = true;

                if (error.response.status == 401) {
                    localStorage.removeItem('loggedIn');
                    //localStore.clearAll();
                    history.push(PathConstants.Invalid);
                }
            }

            if (!error.config.__isRetryRequest) {

                if (error.response?.status == "403" || error.response?.status == "500") {
                    localStorage.removeItem('loggedIn');
                    //localStore.clearAll();
                    history.push(PathConstants.Invalid)
                } else if (error.isAxiosError) {
                    localStorage.removeItem('loggedIn');
                    history.push(PathConstants.Invalid)
                }
                //else if (error.response.status == "400") {
                //    // notification.error(error.response.data.error);
                //}
                //else {
                //    error.response.data.map((message: any) => {
                //        // notification.error(message.ErrorMessage);
                //    })
                //}
                error.config.__isRetryRequest = true;
            }

            return Promise.reject(error);
        });
    }

    set(baseUri: string, pageSize: number) {
        this._baseUri = this.getApiEndpoint();
        this._pageSize = pageSize;
    }

    getPaged(page: number, uriPart?: string | undefined): any {
        this._baseUri = this.getApiEndpoint();
        return axios.get(this._baseUri + uriPart);
    }

    get(uriPart?: string | undefined, data?: any): any {
        this._baseUri = this.getApiEndpoint();
        return axios.get(this._baseUri + uriPart, { params: data, method: 'get', withCredentials: true });
    }

    getWithConfig(uriPart?: string | undefined, config?: any): any {
        this._baseUri = this.getApiEndpoint();
        return axios.get(this._baseUri + uriPart, config);
    }

    post(uriPart: string, data?: any, mapResult?: boolean | undefined): any {
        this._baseUri = this.getApiEndpoint();
        return axios.post(this._baseUri + uriPart, data, { headers: { 'Content-Type': 'multipart/form-data' }, method: 'post', withCredentials: true });
    }

    put(uriPart: string, data?: any, config?: any, mapResult?: boolean): any {
        this._baseUri = this.getApiEndpoint();
        return axios.put(this._baseUri + uriPart, data, { headers: { 'Content-Type': 'multipart/form-data' }, method: 'put', withCredentials: true });
    }

    putJson(data: any, uriPart?: string | undefined): any {
        this._baseUri = this.getApiEndpoint();
        return axios.put(this._baseUri + uriPart, data, { headers: { 'Content-Type': 'application/json; charset=utf-8' }, withCredentials: true });
    }

    postJson(data: any, uriPart?: string | undefined, mapResult?: boolean | undefined): any {
        this._baseUri = this.getApiEndpoint();
        return axios.post(this._baseUri + uriPart, data, { headers: { 'Content-Type': 'application/json; charset=utf-8' }, withCredentials: true });
    }

    delete(id: number, uriPart?: string | undefined): any {
        this._baseUri = this.getApiEndpoint();
        return axios.delete(this._baseUri + uriPart, { headers: { 'Content-Type': 'multipart/form-data' }, method: 'delete', withCredentials: true });

    }

    deleteExtended(data: any, uriPart?: any): any {
        this._baseUri = this.getApiEndpoint();
        throw new Error("Method not implemented.");
    }

    private getApiEndpoint(): string {
        return SIGNER_BASE_URL;
    }
}

export function axiosFetch(clientId?: string): AxiosDataService { return new AxiosDataService(clientId); };

export function storeTokenInMemory(clientId: string, token: string) { inMemoryToken.set(clientId, token); };